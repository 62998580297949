import {faUser} from '@fortawesome/free-regular-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import axios from 'axios'
import * as monetizationConst from '../const/monetizationConst'

function MonetizationDetail() {
  const [monetization, setMonetization] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    getMonetizationData().then(r => {
    })
  }, []);


  let getMonetizationData = async () => {
    try {
      const res = await axios.get(`https://picfixer.app/api/config/get-monetization`);
      const config = res.data?.data ?? [];
      if (!config) {

      } else {
        await setMonetization(config?.monetization);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getLabelByValue = (options, value) => {
    const option = options.find(option => option.value === value);
    return option ? option.label : value; // Return value if not found
  };
  
  return (
    <>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Monetization Detail</h1>
        <div className="align-items-end">
          <Link
            to="/portal/update-monetization-global"
            className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm ml-2"
          >
            <FontAwesomeIcon icon={faUser} className="creating-config mr-2"/>
            Update Monetization
          </Link>
        </div>
      </div>

      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Monetization Data</h6>
        </div>
        <div className="card-body">
          {isLoading ? (
            <img
              src="https://media.giphy.com/media/ZO9b1ntYVJmjZlsWlm/giphy.gif"
              alt="Loading"
            />
          ) : (
            <div>
              <h5>Event Threshold: {monetization?.event_threshold_in_milliseconds} ms</h5>

              <h4>App Events</h4>
              <div className="badge-box">
                <div className="row">
                  {monetization?.app_events?.map((event, index) => (
                    <div key={index} className="col-md-3 mb-3">
                      <div className="card shadow-sm app-event-card">
                        <div className="card-body">
                          <h5 className="card-title">App Event {index + 1}</h5>
                          <p className="card-text">
                            <strong>ID:</strong> {getLabelByValue(monetizationConst.APP_EVENTS_ID_OPTIONS, event.id)}
                          </p>
                          <p className="card-text">
                            <strong>Content Type:</strong> {getLabelByValue(monetizationConst.CONTENT_TYPE_OPTIONS, event.content_type)}
                          </p>
                          <p className="card-text">
                            <strong>Ad Type:</strong> {getLabelByValue(monetizationConst.AD_TYPE_OPTIONS, event.ad_type)}
                          </p>
                          <p className="card-text"><strong>IAP Type:</strong> {event.iap_type}</p>
                          <p><strong>Ad Unit ID:</strong> {event.ad_unit_id}</p>
                          <p><strong>IAP Entitlement Id:</strong> {event.iap_entitlement_id}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <h4>Screen Events</h4>
              <div className="badge-box">
                <div className="row">
                  {monetization?.screen_events?.map((event, index) => (
                    <div key={index} className="col-md-6 mb-3">
                      <div className="card shadow-sm screen-event-card">
                        <div className="card-body">
                          <h5 className="card-title">Screen Event {index + 1}</h5>
                          <p className="card-text">
                            <strong>Screen Name:</strong> {event.screen_name}
                          </p>

                          {/* One-Off Events */}
                          <h6>One-Off Events</h6>
                          {event.one_off_events.length > 0 ? (
                            event.one_off_events.map((oneOff, oneOffIndex) => (
                              <div key={oneOffIndex} className="mb-2 p-2 border rounded">
                                <p><strong>ID:</strong> {getLabelByValue(monetizationConst.SCREEN_EVENTS_ID_OPTIONS, oneOff.id)}</p>
                                <p><strong>Content Type:</strong> {getLabelByValue(monetizationConst.CONTENT_TYPE_OPTIONS, oneOff.content_type)}</p>
                                <p><strong>Ad Type:</strong> {getLabelByValue(monetizationConst.AD_TYPE_OPTIONS, oneOff.ad_type)}</p>
                                <p><strong>IAP Type:</strong> {oneOff.iap_type}</p>
                                <p><strong>Ad Unit ID:</strong> {oneOff.ad_unit_id}</p>
                                <p><strong>IAP Entitlement Id:</strong> {oneOff.iap_entitlement_id}</p>
                              </div>
                            ))
                          ) : (
                            <p className="text-muted">No One-Off Events</p>
                          )}

                          {/* Persistent Events */}
                          <h6>Persistent Events</h6>
                          {event.persistent_events.length > 0 ? (
                            event.persistent_events.map((persistent, persistentIndex) => (
                              <div key={persistentIndex} className="mb-2 p-2 border rounded">
                                <p><strong>ID:</strong> {getLabelByValue(monetizationConst.SCREEN_EVENTS_ID_OPTIONS, persistent.id)}</p>
                                <p><strong>Content Type:</strong> {getLabelByValue(monetizationConst.CONTENT_TYPE_OPTIONS, persistent.content_type)}</p>
                                <p><strong>Ad Type:</strong> {getLabelByValue(monetizationConst.AD_TYPE_OPTIONS, persistent.ad_type)}</p>
                                <p><strong>IAP Type:</strong> {persistent.iap_type}</p>
                                <p><strong>Ad Unit ID:</strong> {persistent.ad_unit_id}</p>
                                <p><strong>IAP Entitlement Id:</strong> {persistent.iap_entitlement_id}</p>
                              </div>
                            ))
                          ) : (
                            <p className="text-muted">No Persistent Events</p>
                          )}

                          {/* Alternative Events */}
                          <h6>Alternative Events</h6>
                          {event.alternative_events.length > 0 ? (
                            event.alternative_events.map((alternative, alternativeIndex) => (
                              <div key={alternativeIndex} className="mb-2 p-2 border rounded">
                                <p><strong>ID:</strong> {getLabelByValue(monetizationConst.SCREEN_EVENTS_ID_OPTIONS, alternative.id)}</p>
                                <p><strong>Content Type:</strong> {getLabelByValue(monetizationConst.CONTENT_TYPE_OPTIONS, alternative.content_type)}</p>
                                <p><strong>Ad Type:</strong> {getLabelByValue(monetizationConst.AD_TYPE_OPTIONS, alternative.ad_type)}</p>
                                <p><strong>IAP Type:</strong> {alternative.iap_type}</p>
                                <p><strong>Ad Unit ID:</strong> {alternative.ad_unit_id}</p>
                                <p><strong>IAP Entitlement Id:</strong> {alternative.iap_entitlement_id}</p>
                              </div>
                            ))
                          ) : (
                            <p className="text-muted">No Alternative Events</p>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default MonetizationDetail
