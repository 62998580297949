import './App.css';
import "./sb-admin-2.min.css";
import Dashboard from './Dashboard';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Login from './Login';
import Userlist from './Userlist';
import FairyAiList from "./fairy-ai/List";
import Portal from './Portal';
import UserCreate from './UserCreate';
import UserView from './UserView';
import UserEdit from './UserEdit';
import Create from "./fairy-ai/Create";
import Edit from "./fairy-ai/Edit";
import FaceDanceList from "./face-dance/List";
import FaceDanceEdit from "./face-dance/Edit";
import FaceDanceCreate from "./face-dance/Create";
import FaceSwapList from "./face-swap/List";
import FaceSwapEdit from "./face-swap/Edit";
import FaceSwapCreate from "./face-swap/Create";
import CategoryList from "./category/List";
import CategoryEdit from "./category/Edit";
import CategoryCreate from "./category/Create";
import RequireAuth from "./components/RequireAuth";
import ConfigList from "./config/List";
import ConfigCreate from "./config/Create";
import ConfigEdit from "./config/Edit";
import MonetizationDetail from "./monetization-global/Detail";
import UpdateMonetization from "./monetization-global/Edit";
import MonetizationList from "./monetization/List"
import MonetizationCreate from "./monetization/Create"
import MonetizationEdit from "./monetization/Edit"
import ServiceKeyList from "./service-key/List";
import ServiceKeyCreate from "./service-key/Create";
import ServiceKeyEdit from "./service-key/Edit";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login/>}/>
        <Route path="/portal" element={<RequireAuth/>}>
          <Route path="" element={<Portal/>}>
            <Route path="dashboard" element={<Dashboard/>}/>
            <Route path="user-list" element={<Userlist/>}/>
            <Route path="create-user" element={<UserCreate/>}/>
            <Route path="user-view/:id" element={<UserView/>}/>
            <Route path="user-edit/:id" element={<UserEdit/>}/>

            <Route path="fairy-ai-list" element={<FairyAiList/>}/>
            <Route path="create-fairy-ai" element={<Create/>}/>
            <Route path="fairy-ai-edit/:id" element={<Edit/>}/>

            <Route path="face-dance-list" element={<FaceDanceList/>}/>
            <Route path="create-face-dance" element={<FaceDanceCreate/>}/>
            <Route path="face-dance-edit/:id" element={<FaceDanceEdit/>}/>

            <Route path="face-swap-list" element={<FaceSwapList/>}/>
            <Route path="create-face-swap" element={<FaceSwapCreate/>}/>
            <Route path="face-swap-edit/:id" element={<FaceSwapEdit/>}/>

            <Route path="category-list" element={<CategoryList/>}/>
            <Route path="create-category" element={<CategoryCreate/>}/>
            <Route path="category-edit/:id" element={<CategoryEdit/>}/>

            <Route path="config-list" element={<ConfigList/>}/>
            <Route path="create-config" element={<ConfigCreate/>}/>
            <Route path="config-edit/:id" element={<ConfigEdit/>}/>

            <Route path="monetization-global" element={<MonetizationDetail/>}/>
            <Route path="update-monetization-global" element={<UpdateMonetization/>}/>

            <Route path="monetization-list" element={<MonetizationList/>}/>
            <Route path="create-monetization" element={<MonetizationCreate/>}/>
            <Route path="monetization-edit/:id" element={<MonetizationEdit/>}/>

            <Route path="service-key-list" element={<ServiceKeyList/>}/>
            <Route path="create-service-key" element={<ServiceKeyCreate/>}/>
            <Route path="service-key-edit/:id" element={<ServiceKeyEdit/>}/>

          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
