import React, { useState } from "react";
import { CardBody, CardHeader, Modal, Button, Form } from "react-bootstrap";
import axios from "axios";

const MonetizationImport = ({ show, onClose, monetization, onSaveSuccessfully}) => {
  const [fileContent, setFileContent] = useState(null);
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const json = JSON.parse(e.target.result);
          setFileContent(json);
          setFile(selectedFile);
        } catch (error) {
          alert("Invalid JSON file.");
        }
      };
      reader.readAsText(selectedFile);
    }
  };

  const handleImport = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("content", file);

      try {
        await axios.post(`https://picfixer.app/api/monetization/import/${monetization?.id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        setFileContent(null);
        setFile(null);
        onClose();
        onSaveSuccessfully();
      } catch (error) {
        alert("Import failed. Please try again.");
      }
    } else {
      alert("Please select a valid JSON file.");
    }
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const json = JSON.parse(e.target.result);
          setFileContent(json);
          setFile(droppedFile);
        } catch (error) {
          alert("Invalid JSON file.");
        }
      };
      reader.readAsText(droppedFile);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };


  return (
    <Modal show={show} onHide={onClose} size={"lg"}>
      <CardHeader>
        View Monetization: {monetization?.id}
      </CardHeader>
      <CardBody>
        <h3>Platform: {monetization?.platform}</h3>
        <h3>Env: {monetization?.environment}</h3>
        <Form.Group controlId="fileInput" className="mt-3">
          <Form.Label>Drag & Drop JSON file here or click to upload</Form.Label>
          <Form.Control type="file" accept=".json" onChange={handleFileChange} />
        </Form.Group>

        <Button variant="primary" className="mt-3" onClick={handleImport}>
          Import
        </Button>
      </CardBody>
    </Modal>
  );
};

export default MonetizationImport;