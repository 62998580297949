import React, { useState } from "react";
import {Field, FieldArray, Form, Formik} from "formik";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import * as monetizationConst from "../const/monetizationConst";

const MonetizationCreateForm = () => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();


  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("platform", values.platform);
      formData.append("version", values.version);
      formData.append("environment", values.environment);

      formData.append("content[event_threshold_in_milliseconds]", values.content.event_threshold_in_milliseconds);

      values.content.app_events.forEach((event, index) => {
        formData.append(`content[app_events][${index}][id]`, event.id);
        formData.append(`content[app_events][${index}][content_type]`, event.content_type);
        formData.append(`content[app_events][${index}][ad_type]`, event.ad_type);
        formData.append(`content[app_events][${index}][iap_type]`, event.iap_type);
        formData.append(`content[app_events][${index}][ad_unit_id]`, event.ad_unit_id);
        formData.append(`content[app_events][${index}][iap_entitlement_id]`, event.iap_entitlement_id);
      });

      values.content.screen_events.forEach((event, index) => {
        formData.append(`content[screen_events][${index}][screen_name]`, event.screen_name);

        event.one_off_events.forEach((oneOff, oneOffIndex) => {
          formData.append(`content[screen_events][${index}][one_off_events][${oneOffIndex}][id]`, oneOff.id);
          formData.append(`content[screen_events][${index}][one_off_events][${oneOffIndex}][content_type]`, oneOff.content_type);
          formData.append(`content[screen_events][${index}][one_off_events][${oneOffIndex}][ad_type]`, oneOff.ad_type);
          formData.append(`content[screen_events][${index}][one_off_events][${oneOffIndex}][iap_type]`, oneOff.iap_type);
          formData.append(`content[screen_events][${index}][one_off_events][${oneOffIndex}][ad_unit_id]`, oneOff.ad_unit_id);
          formData.append(`content[screen_events][${index}][one_off_events][${oneOffIndex}][iap_entitlement_id]`, oneOff.iap_entitlement_id);
        });

        event.persistent_events.forEach((persistent, persistentIndex) => {
          formData.append(`content[screen_events][${index}][persistent_events][${persistentIndex}][id]`, persistent.id);
          formData.append(`content[screen_events][${index}][persistent_events][${persistentIndex}][content_type]`, persistent.content_type);
          formData.append(`content[screen_events][${index}][persistent_events][${persistentIndex}][ad_type]`, persistent.ad_type);
          formData.append(`content[screen_events][${index}][persistent_events][${persistentIndex}][iap_type]`, persistent.iap_type);
          formData.append(`content[screen_events][${index}][persistent_events][${persistentIndex}][ad_unit_id]`, persistent.ad_unit_id);
          formData.append(`content[screen_events][${index}][persistent_events][${persistentIndex}][iap_entitlement_id]`, persistent.iap_entitlement_id);
        });

        event.alternative_events.forEach((alternative, alternativeIndex) => {
          formData.append(`content[screen_events][${index}][alternative_events][${alternativeIndex}][id]`, alternative.id);
          formData.append(`content[screen_events][${index}][alternative_events][${alternativeIndex}][content_type]`, alternative.content_type);
          formData.append(`content[screen_events][${index}][alternative_events][${alternativeIndex}][ad_type]`, alternative.ad_type);
          formData.append(`content[screen_events][${index}][alternative_events][${alternativeIndex}][iap_type]`, alternative.iap_type);
          formData.append(`content[screen_events][${index}][alternative_events][${alternativeIndex}][ad_unit_id]`, alternative.ad_unit_id);
          formData.append(`content[screen_events][${index}][alternative_events][${alternativeIndex}][iap_entitlement_id]`, alternative.iap_entitlement_id);
        });
      });


      await axios.post(`https://picfixer.app/api/monetization/create`, formData, {
        headers: {'Content-Type': 'multipart/form-data'}
      });

      setLoading(false);
      navigate("/portal/monetization-list");
    } catch (error) {
      console.error("Error submitting form:", error);
      setLoading(false);
    }
  }
  return (
    <div className="container mt-4">
      <h2>Create Monetization</h2>
      <Formik
        enableReinitialize
        initialValues={{
          platform: 'ios',
          version: '',
          environment: '',
          content: {
            event_threshold_in_milliseconds: 0,
            app_events: [],
            screen_events: [],
          }
        }}
        onSubmit={onSubmit}
      >
        {({values, handleSubmit}) => (
          <Form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label>Platform</label>
              <Field as="select" name='platform' className="form-control">
                {monetizationConst.PLATFORM_OPTIONS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Field>
            </div>
            <div className="mb-3">
              <label>Version</label>
              <Field type="input" name="version" className="form-control"/>
            </div>
            <div className="mb-3">
              <label>Environment</label>
              <Field type="input" name="environment" className="form-control"/>
            </div>
            <div className="mb-3">
              <label>Event Threshold (ms)</label>
              <Field type="number" name="content.event_threshold_in_milliseconds" className="form-control"/>
            </div>

            <h4>App Events</h4>
            <FieldArray name="content.app_events">
              {({push, remove}) => (
                <div>
                  {values?.content?.app_events.map((event, index) => (
                    <div key={index} className="mb-3 p-3 border">
                      <div className="mb-2">
                        <label>ID</label>
                        <Field as="select" name={`content.app_events.${index}.id`} className="form-control">
                          {monetizationConst.APP_EVENTS_ID_OPTIONS.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className="mb-2">
                        <label>Content Type</label>
                        <Field as="select" name={`content.app_events.${index}.content_type`} className="form-control">
                          {monetizationConst.CONTENT_TYPE_OPTIONS.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className="mb-2">
                        <label>Ad Type</label>
                        <Field as="select" name={`content.app_events.${index}.ad_type`} className="form-control">
                          {monetizationConst.AD_TYPE_OPTIONS.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className="mb-2">
                        <label>IAP Type</label>
                        <Field type="text" name={`content.app_events.${index}.iap_type`} className="form-control"/>
                      </div>
                      <div className="mb-2">
                        <label>AD Unit Id</label>
                        <Field type="text" name={`content.app_events.${index}.ad_unit_id`} className="form-control"/>
                      </div>
                      <div className="mb-2">
                        <label>IAP Entitlement Id</label>
                        <Field type="text" name={`content.app_events.${index}.iap_entitlement_id`} className="form-control"/>
                      </div>
                      <button type="button" className="btn btn-danger btn-sm" onClick={() => remove(index)}>Delete
                      </button>
                    </div>
                  ))}
                  <button type="button" className="btn btn-primary"
                          onClick={() => push({id: "", content_type: "", ad_type: "", iap_type: "", ad_unit_id: "", iap_entitlement_id: ""})}>
                    Add Event
                  </button>
                </div>
              )}
            </FieldArray>

            <h4>Screen Events</h4>
            <FieldArray name="content.screen_events">
              {({push, remove}) => (
                <div>
                  {values.content.screen_events.map((screenEvent, index) => (
                    <div key={index} className="mb-3 p-3 border">
                      <div className="mb-2">
                        <label>Screen Name</label>
                        <Field type="text" name={`content.screen_events.${index}.screen_name`} className="form-control"/>
                      </div>

                      {/* One-Off Events */}
                      <h5>One-Off Events</h5>
                      <FieldArray name={`content.screen_events.${index}.one_off_events`}>
                        {({push, remove}) => (
                          <div>
                            {screenEvent?.one_off_events?.map((event, eventIndex) => (
                              <div key={eventIndex} className="mb-2 p-2 border">
                                <label>ID</label>
                                <Field as="select" name={`content.screen_events.${index}.one_off_events.${eventIndex}.id`}
                                       className="form-control">
                                  {monetizationConst.SCREEN_EVENTS_ID_OPTIONS.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                  ))}
                                </Field>
                                <label>Content Type</label>
                                <Field as="select"
                                       name={`content.screen_events.${index}.one_off_events.${eventIndex}.content_type`}
                                       className="form-control">
                                  {monetizationConst.CONTENT_TYPE_OPTIONS.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                  ))}
                                </Field>
                                <label>AD Type</label>
                                <Field as="select" name={`content.screen_events.${index}.one_off_events.${eventIndex}.ad_type`}
                                       className="form-control">
                                  {monetizationConst.AD_TYPE_OPTIONS.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                  ))}
                                </Field>
                                <label>IAP Type</label>
                                <Field type="text" name={`content.screen_events.${index}.one_off_events.${eventIndex}.iap_type`}
                                       className="form-control"/>
                                <label>AD Unit ID</label>
                                <Field type="text" name={`content.screen_events.${index}.one_off_events.${eventIndex}.ad_unit_id`}
                                       className="form-control"/>
                                <label>IAP Entitlement Id</label>
                                <Field type="text" name={`content.screen_events.${index}.one_off_events.${eventIndex}.iap_entitlement_id`}
                                       className="form-control"/>
                                <button type="button" className="btn btn-danger btn-sm mt-2"
                                        onClick={() => remove(eventIndex)}>Delete
                                </button>
                              </div>
                            ))}
                            <button type="button" className="btn btn-primary btn-sm" onClick={() => push({
                              id: monetizationConst.SCREEN_EVENTS_ID_OPTIONS[0]?.value || "",
                              content_type: monetizationConst.CONTENT_TYPE_OPTIONS[0]?.value || "",
                              ad_type: monetizationConst.AD_TYPE_OPTIONS[0]?.value || "",
                              iap_type: "",
                              ad_unit_id: "",
                              iap_entitlement_id: ""
                            })}
                            >Add One-Off Event
                            </button>
                          </div>
                        )}
                      </FieldArray>

                      {/* Persistent Events */}
                      <h5>Persistent Events</h5>
                      <FieldArray name={`content.screen_events.${index}.persistent_events`}>
                        {({push, remove}) => (
                          <div>
                            {screenEvent?.persistent_events?.map((event, eventIndex) => (
                              <div key={eventIndex} className="mb-2 p-2 border">
                                <label>ID</label>
                                <Field as="select" name={`content.screen_events.${index}.persistent_events.${eventIndex}.id`}
                                       className="form-control">
                                  {monetizationConst.SCREEN_EVENTS_ID_OPTIONS.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                  ))}
                                </Field>
                                <label>Content Type</label>
                                <Field as="select"
                                       name={`content.screen_events.${index}.persistent_events.${eventIndex}.content_type`}
                                       className="form-control">
                                  {monetizationConst.CONTENT_TYPE_OPTIONS.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                  ))}
                                </Field>
                                <label>Ad Type</label>
                                <Field as="select"
                                       name={`content.screen_events.${index}.persistent_events.${eventIndex}.ad_type`}
                                       className="form-control">
                                  {monetizationConst.AD_TYPE_OPTIONS.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                  ))}
                                </Field>
                                <label>IAP Type</label>
                                <Field type="text"
                                       name={`content.screen_events.${index}.persistent_events.${eventIndex}.iap_type`}
                                       className="form-control"/>
                                <label>Ad Unit Id</label>
                                <Field type="text"
                                       name={`content.screen_events.${index}.persistent_events.${eventIndex}.ad_unit_id`}
                                       className="form-control"/>
                                <label>Iap Entitlement Id</label>
                                <Field type="text"
                                       name={`content.screen_events.${index}.persistent_events.${eventIndex}.iap_entitlement_id`}
                                       className="form-control"/>
                                <button type="button" className="btn btn-danger btn-sm mt-2"
                                        onClick={() => remove(eventIndex)}>Delete
                                </button>
                              </div>
                            ))}
                            <button type="button" className="btn btn-primary btn-sm" onClick={() => push({
                              id: monetizationConst.SCREEN_EVENTS_ID_OPTIONS[0]?.value || "",
                              content_type: monetizationConst.CONTENT_TYPE_OPTIONS[0]?.value || "",
                              ad_type: monetizationConst.AD_TYPE_OPTIONS[0]?.value || "",
                              iap_type: "",
                              ad_unit_id: "",
                              iap_entitlement_id: ""
                            })}
                            >Add Persistent Event
                            </button>
                          </div>
                        )}
                      </FieldArray>

                      {/* alternative_events */}
                      <h5>Alternative Events</h5>
                      <FieldArray name={`content.screen_events.${index}.alternative_events`}>
                        {({push, remove}) => (
                          <div>
                            {screenEvent?.alternative_events?.map((event, eventIndex) => (
                              <div key={eventIndex} className="mb-2 p-2 border">
                                <label>ID</label>
                                <Field as="select" name={`content.screen_events.${index}.alternative_events.${eventIndex}.id`}
                                       className="form-control">
                                  {monetizationConst.SCREEN_EVENTS_ID_OPTIONS.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                  ))}
                                </Field>
                                <label>Content Type</label>
                                <Field as="select"
                                       name={`content.screen_events.${index}.alternative_events.${eventIndex}.content_type`}
                                       className="form-control">
                                  {monetizationConst.CONTENT_TYPE_OPTIONS.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                  ))}
                                </Field>
                                <label>Ad Type</label>
                                <Field as="select"
                                       name={`content.screen_events.${index}.alternative_events.${eventIndex}.ad_type`}
                                       className="form-control">
                                  {monetizationConst.AD_TYPE_OPTIONS.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                  ))}
                                </Field>
                                <label>IAP Type</label>
                                <Field type="text"
                                       name={`content.screen_events.${index}.alternative_events.${eventIndex}.iap_type`}
                                       className="form-control"/>
                                <label>Ad Unit Id</label>
                                <Field type="text"
                                       name={`content.screen_events.${index}.alternative_events.${eventIndex}.ad_unit_id`}
                                       className="form-control"/>
                                <label>Iap Entitlement Id</label>
                                <Field type="text"
                                       name={`content.screen_events.${index}.alternative_events.${eventIndex}.iap_entitlement_id`}
                                       className="form-control"/>
                                <button type="button" className="btn btn-danger btn-sm mt-2"
                                        onClick={() => remove(eventIndex)}>Delete
                                </button>
                              </div>
                            ))}
                            <button type="button" className="btn btn-primary btn-sm" onClick={() => push({
                              id: monetizationConst.SCREEN_EVENTS_ID_OPTIONS[0]?.value || "",
                              content_type: monetizationConst.CONTENT_TYPE_OPTIONS[0]?.value || "",
                              ad_type: monetizationConst.AD_TYPE_OPTIONS[0]?.value || "",
                              iap_type: "",
                              ad_unit_id: "",
                              iap_entitlement_id: ""
                            })}
                            >Add Alternative Event
                            </button>
                          </div>
                        )}
                      </FieldArray>

                      <button type="button" className="btn btn-danger btn-sm mt-3" onClick={() => remove(index)}>Delete
                        Screen Event
                      </button>
                    </div>
                  ))}
                  <button type="button" className="btn btn-primary"
                          onClick={() => push({screen_name: '', one_off_events: [], persistent_events: [], alternative_events: []})}>Add Screen
                    Event
                  </button>
                </div>
              )}
            </FieldArray>

            <button type="submit" className="btn btn-success mt-3" disabled={isLoading}>
              {isLoading ? "Saving..." : "Save Configuration"}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MonetizationCreateForm;