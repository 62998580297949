import { faUser } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'

function ConfigList() {
  const [configList, setConfigList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);

  useEffect(() => {
    getConfigList().then(r => {});
  }, []);



  let getConfigList = async () => {
    try {
      const res = await axios.get("https://picfixer.app/api/config/list");
      setConfigList(res.data?.data?.items ?? []);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  let handleDelete = async (id) => {
    try {
      const confirmDelete = window.confirm("Are you sure do you want to delete the data?");
      if (confirmDelete) {
        await axios.post(`https://picfixer.app/api/config/delete/${id}`);
        await getConfigList();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const filteredList = configList.filter(config =>
    (filterText === "" || config?.code.toLowerCase().includes(filterText.toLowerCase()))
  );

// Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredList.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredList.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
      <>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Config List</h1>
          <div className="align-items-end">
            <Link to="/portal/create-config" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
              <FontAwesomeIcon icon={faUser} className="creating-config" />
              Create Config
            </Link>
          </div>
        </div>
        {/* <!-- DataTables --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">DataTables</h6>
          </div>
          <div className="card-body">
            <div className="row pl-3">
              <div className="mb-3 col-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Filter by Name"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                />
              </div>
            </div>

            {
              isLoading ? <img src='https://media.giphy.com/media/ZO9b1ntYVJmjZlsWlm/giphy.gif' alt="Loading" />
                  : <div className="table-responsive">
                    <nav>
                      <ul className="pagination">
                        {[...Array(totalPages).keys()].map(number => (
                            <li key={number + 1} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
                              <a onClick={() => paginate(number + 1)} className="page-link">
                                {number + 1}
                              </a>
                            </li>
                        ))}
                      </ul>
                    </nav>
                    <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                      <thead>
                      <tr>
                        <th>Id</th>
                        <th>Code</th>
                        <th>Main Service</th>
                        <th>Backup Service</th>
                        <th>Backup Service Countries</th>
                        <th>Disable Countries</th>
                        <th>Action</th>
                      </tr>
                      </thead>
                      <tfoot>
                      <tr>
                        <th>Id</th>
                        <th>Code</th>
                        <th>Main Service</th>
                        <th>Backup Service</th>
                        <th>Backup Service Countries</th>
                        <th>Disable Countries</th>
                        <th>Action</th>
                      </tr>
                      </tfoot>
                      <tbody>
                      {currentItems.map((config) => {
                        const code = config?.code;
                        return (
                            <tr key={config.id}>
                              <td>{config.id}</td>
                              <td>{code}</td>
                              <td>{config?.main_service}</td>
                              <td>{config?.backup_service}</td>
                              <td>{config?.backup_service_countries}</td>
                              <td>{config?.disable_countries}</td>
                              <th>
                                <Link to={`/portal/config-edit/${config.id}`} className='btn btn-info btn-sm mr-1'>Edit</Link>
                                <button onClick={() => handleDelete(config.id)} className='btn btn-danger btn-sm mr-1'>Delete</button>
                              </th>
                            </tr>
                        )
                      })}
                      </tbody>
                    </table>
                    <nav>
                      <ul className="pagination">
                        {[...Array(totalPages).keys()].map(number => (
                            <li key={number + 1} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
                              <a onClick={() => paginate(number + 1)} className="page-link">
                                {number + 1}
                              </a>
                            </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
            }
          </div>
        </div>
      </>
  )
}

export default ConfigList
