const CONTENT_TYPE_OPTIONS = [
  { value: "none", label: "None" },
  { value: "ad", label: "Ad" },
  { value: "iap", label: "IAP" },
];

const APP_EVENTS_ID_OPTIONS = [
  { value: "app_opened", label: "App Opened" },
  { value: "app_resumed", label: "App Resumed" },
];

const AD_TYPE_OPTIONS = [
  { value: "interstitial_ad", label: "Interstitial Ad" },
  { value: "reward_ad", label: "Reward Ad" },
  { value: "app_open_ad", label: "App Open Ad" },
  { value: "small_native", label: "Small Native" },
  { value: "medium_native", label: "Medium Native" },
  { value: "large_native", label: "Large Native" },
  { value: "banner", label: "Banner" },
  { value: "collapsible_banner", label: "Collapsible Banner" },
  { value: "full_native", label: "Full Native" },
];

const SCREEN_EVENTS_ID_OPTIONS = [
  { value: "processing", label: "Processing" },
  { value: "process_success", label: "Process Success" },
  { value: "go_next", label: "Go Next" },
  { value: "header", label: "Header" },
  { value: "list_interleave", label: "List Interleave" },
  { value: "footer", label: "Footer" },
];

const getLabelByValue = (options, value) => {
  const option = options.find(option => option.value === value);
  return option ? option.label : value; // Return value if not found
};

const PLATFORM_OPTIONS = [
  { value: 'ios', label: 'IOS'},
  { value: 'android', label: 'ANDROID'},
]

export {
  CONTENT_TYPE_OPTIONS,
  APP_EVENTS_ID_OPTIONS,
  AD_TYPE_OPTIONS,
  SCREEN_EVENTS_ID_OPTIONS,
  PLATFORM_OPTIONS,
  getLabelByValue
};
