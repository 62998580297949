import axios from 'axios';
import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom';
import { CONFIG_SERVICE_ID } from "../const/configConst";

function ServiceKeyCreate() {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {

  }, [])


  const myFormik = useFormik(
    {
      initialValues: {
        code: "",
        name: "",
        key: ""
      },
      validate: (values) => {
        return {};
      },
      onSubmit: async (values) => {
        try {
          setLoading(true);
          const formData = new FormData();
          formData.append("code", values.code);
          formData.append("name", values.name);
          formData.append("key", values.key);

          await axios.post(`https://picfixer.app/api/service-key/create`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          navigate("/portal/service-key-list");
        } catch (error) {
          console.log(error);
          alert("Validation failed");
          setLoading(false);
        }

      }

    });


  return (
    <div className='container'>

      <form onSubmit={myFormik.handleSubmit}>
        <div className='row'>
          <div className="col-lg-6">
            <label className='font-weight-bold'>Code</label>
            <select
              name='code'
              value={myFormik.values.code}
              onChange={myFormik.handleChange}
              className={`form-control ${myFormik.errors.code ? "is-invalid" : ""}`}
            >
              <option value="">----Select----</option>
              {CONFIG_SERVICE_ID.map((service, index) => (
                <option key={index} value={service}>{service}</option>
              ))}
            </select>
            <span style={{color: "red"}}>{myFormik.errors.code}</span>
          </div>

          <div className="col-lg-6">
            <label className='font-weight-bold'>Name</label>
            <input
              name='name'
              value={myFormik.values.name}
              onChange={myFormik.handleChange}
              type="text"
              className={`form-control ${myFormik.errors.name ? "is-invalid" : ""}`}
            />
            <span style={{color: "red"}}>{myFormik.errors.name}</span>
          </div>

          <div className="col-lg-6">
            <label className='font-weight-bold'>API Key</label>
            <input
              name='key'
              value={myFormik.values.key}
              onChange={myFormik.handleChange}
              type="text"
              className={`form-control ${myFormik.errors.key ? "is-invalid" : ""}`}
            />
            <span style={{color: "red"}}>{myFormik.errors.key}</span>
          </div>

          <div className='col-lg-12 mt-3'>
            <input
              disabled={isLoading}
              type="submit"
              value={isLoading ? "Submitting..." : "Save"}
              className='btn btn-primary'
            />
          </div>
        </div>

      </form>
      {/* {JSON.stringify(myFormik.values)} */}
    </div>
  );
}

export default ServiceKeyCreate