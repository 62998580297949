import React from "react";
import {CardBody, CardHeader, Modal} from "react-bootstrap";
import ReactJson from "react-json-view";

const MonetizationView = ({show, onClose, monetization}) => {

  return (
    <Modal show={show} onHide={onClose} size={"lg"}>
      <CardHeader>
        View Monetization: {monetization?.id}
      </CardHeader>
      <CardBody>
        <h3>Platform: {monetization?.platform}</h3>
        <h3>Env: {monetization?.environment}</h3>

        <label>Content: </label>
        <ReactJson
          src={monetization?.content}
          collapsed={true}
          enableClipboard={false}
          displayDataTypes={true}
          quotesOnKeys={false}
        />;
      </CardBody>
    </Modal>
  );
};

export default MonetizationView;