import React, {useEffect, useState} from "react";
import {Field, FieldArray, Form, Formik, useFormik} from "formik";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import * as monetizationConst from "../const/monetizationConst";

const MonetizationEditForm = () => {
  const params = useParams();
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [monetization, setMonetization] = useState({});

  let getMonetizationData = async () => {
    try {
      const res = await axios.get(`https://picfixer.app/api/monetization/detail/${params.id}`);
      const monetization = res.data?.data ?? []
      if (!monetization) {
        navigate("/portal/monetization-list")
      } else {
        await setMonetization(monetization);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      navigate("/portal/monetization-list")
    }
  }

  useEffect(() => {
    getMonetizationData().then(r => {});
  }, []);

  const myFormik = useFormik({
    enableReinitialize: true,
    initialValues: monetization ? {
      platform: monetization.platform || 'ios',
      version: monetization.version,
      environment: monetization.environment || '',
      content: monetization.content || {
        event_threshold_in_milliseconds: 0,
        app_events: [],
        screen_events: [],
      },
    } : {},

    validate: (values) => {
      return {};
    },

    onSubmit: async (values) => {
      try {
        setLoading(true);
        const formData = new FormData();

        const appendIfValid = (key, value) => {
          if (value !== null && value !== undefined) {
            formData.append(key, value);
          } else {
            formData.append(key, '');
          }
        };

        appendIfValid(`platform`, values.platform);
        appendIfValid(`version`, values.version);
        appendIfValid(`environment`, values.environment);
        appendIfValid(`content[event_threshold_in_milliseconds]`, values.content.event_threshold_in_milliseconds);

        values?.content?.app_events?.forEach((event, index) => {
          appendIfValid(`content[app_events][${index}][id]`, event.id ?? '');
          appendIfValid(`content[app_events][${index}][content_type]`, event.content_type ?? '');
          appendIfValid(`content[app_events][${index}][ad_type]`, event.ad_type ?? '');
          appendIfValid(`content[app_events][${index}][iap_type]`, event.iap_type ?? '');
          appendIfValid(`content[app_events][${index}][ad_unit_id]`, event.ad_unit_id ?? '');
          appendIfValid(`content[app_events][${index}][iap_entitlement_id]`, event.iap_entitlement_id ?? '');
        });

        values?.content?.screen_events.forEach((event, index) => {
          appendIfValid(`content[screen_events][${index}][screen]`, event.screen);

          event?.one_off_events?.forEach((oneOff, oneOffIndex) => {
            appendIfValid(`content[screen_events][${index}][one_off_events][${oneOffIndex}][id]`, oneOff.id);
            appendIfValid(`content[screen_events][${index}][one_off_events][${oneOffIndex}][content_type]`, oneOff.content_type);
            appendIfValid(`content[screen_events][${index}][one_off_events][${oneOffIndex}][ad_type]`, oneOff.ad_type);
            appendIfValid(`content[screen_events][${index}][one_off_events][${oneOffIndex}][iap_type]`, oneOff.iap_type ?? '');
            appendIfValid(`content[screen_events][${index}][one_off_events][${oneOffIndex}][ad_unit_id]`, oneOff.ad_unit_id ?? '');
            appendIfValid(`content[screen_events][${index}][one_off_events][${oneOffIndex}][iap_entitlement_id]`, oneOff.iap_entitlement_id ?? '');
          });

          event?.persistent_events?.forEach((persistent, persistentIndex) => {
            appendIfValid(`content[screen_events][${index}][persistent_events][${persistentIndex}][id]`, persistent.id);
            appendIfValid(`content[screen_events][${index}][persistent_events][${persistentIndex}][content_type]`, persistent.content_type);
            appendIfValid(`content[screen_events][${index}][persistent_events][${persistentIndex}][ad_type]`, persistent.ad_type);
            appendIfValid(`content[screen_events][${index}][persistent_events][${persistentIndex}][iap_type]`, persistent.iap_type);
            appendIfValid(`content[screen_events][${index}][persistent_events][${persistentIndex}][ad_unit_id]`, persistent.ad_unit_id);
            appendIfValid(`content[screen_events][${index}][persistent_events][${persistentIndex}][iap_entitlement_id]`, persistent.iap_entitlement_id);
          });

          event?.alternative_events?.forEach((alternative, alternativeIndex) => {
            appendIfValid(`content[screen_events][${index}][alternative_events][${alternativeIndex}][id]`, alternative.id);
            appendIfValid(`content[screen_events][${index}][alternative_events][${alternativeIndex}][content_type]`, alternative.content_type);
            appendIfValid(`content[screen_events][${index}][alternative_events][${alternativeIndex}][ad_type]`, alternative.ad_type);
            appendIfValid(`content[screen_events][${index}][alternative_events][${alternativeIndex}][iap_type]`, alternative.iap_type);
            appendIfValid(`content[screen_events][${index}][alternative_events][${alternativeIndex}][ad_unit_id]`, alternative.ad_unit_id);
            appendIfValid(`content[screen_events][${index}][alternative_events][${alternativeIndex}][iap_entitlement_id]`, alternative.iap_entitlement_id);
          });
        });

        await axios.post(`https://picfixer.app/api/monetization/update/${params.id}`, formData, {
          headers: {'Content-Type': 'multipart/form-data'}
        });

        setLoading(false);
        navigate("/portal/monetization-list");
      } catch (error) {
        console.error("Error submitting form:", error);
        setLoading(false);
      }
    }
  })

  useEffect(() => {
    myFormik.setValues(monetization);
  }, [monetization]);

  return (
    <div className="container mt-4">
      <h2>Edit Monetization: {params.id}</h2>
      <Formik
        enableReinitialize
        initialValues={monetization}
        onSubmit={async (values) => {
          await myFormik.setValues(values);
          await myFormik.handleSubmit();
        }}
      >
        {({values, handleSubmit}) => (
          <Form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label>Platform</label>
              <Field as="select" name='platform' className="form-control">
                {monetizationConst.PLATFORM_OPTIONS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Field>
            </div>
            <div className="mb-3">
              <label>Version</label>
              <Field type="input" name="version" className="form-control"/>
            </div>
            <div className="mb-3">
              <label>Environment</label>
              <Field type="input" name="environment" className="form-control"/>
            </div>
            <div className="mb-3">
              <label>Event Threshold (ms)</label>
              <Field type="number" name="content.event_threshold_in_milliseconds" className="form-control"/>
            </div>

            <h4>App Events</h4>
            {Array.isArray(values.content?.app_events) && (
            <FieldArray name="content.app_events">
              {({push, remove}) => (
                <div>
                  {values?.content?.app_events?.map((event, index) => (
                    <div key={index} className="mb-3 p-3 border">
                      <div className="mb-2">
                        <label>ID</label>
                        <Field as="select" name={`content.app_events.${index}.id`} className="form-control">
                          {monetizationConst.APP_EVENTS_ID_OPTIONS.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className="mb-2">
                        <label>Content Type</label>
                        <Field as="select" name={`content.app_events.${index}.content_type`} className="form-control">
                          {monetizationConst.CONTENT_TYPE_OPTIONS.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className="mb-2">
                        <label>Ad Type</label>
                        <Field as="select" name={`content.app_events.${index}.ad_type`} className="form-control">
                          {monetizationConst.AD_TYPE_OPTIONS.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className="mb-2">
                        <label>IAP Type</label>
                        <Field type="text" name={`content.app_events.${index}.iap_type`} className="form-control"/>
                      </div>
                      <div className="mb-2">
                        <label>AD Unit Id</label>
                        <Field type="text" name={`content.app_events.${index}.ad_unit_id`} className="form-control"/>
                      </div>
                      <div className="mb-2">
                        <label>IAP Entitlement Id</label>
                        <Field type="text" name={`content.app_events.${index}.iap_entitlement_id`} className="form-control"/>
                      </div>
                      <button type="button" className="btn btn-danger btn-sm" onClick={() => remove(index)}>Delete
                      </button>
                    </div>
                  ))}
                  <button type="button" className="btn btn-primary"
                          onClick={() => push({id: "", content_type: "", ad_type: "", iap_type: "", ad_unit_id: "", iap_entitlement_id: ""})}>
                    Add Event
                  </button>
                </div>
              )}
            </FieldArray>
            )}
            <h4>Screen Events</h4>
            {Array.isArray(values.content?.screen_events) && (
            <FieldArray name="content.screen_events">
              {({push, remove}) => (
                <div>
                  {values.content?.screen_events.map((screenEvent, index) => (
                    <div key={index} className="mb-3 p-3 border">
                      <div className="mb-2">
                        <label>Screen Name</label>
                        <Field type="text" name={`content.screen_events.${index}.screen`} className="form-control"/>
                      </div>

                      {/* One-Off Events */}
                      <h5>One-Off Events</h5>
                      <FieldArray name={`content.screen_events.${index}.one_off_events`}>
                        {({push, remove}) => (
                          <div>
                            {screenEvent?.one_off_events?.map((event, eventIndex) => (
                              <div key={eventIndex} className="mb-2 p-2 border">
                                <label>ID</label>
                                <Field as="select" name={`content.screen_events.${index}.one_off_events.${eventIndex}.id`}
                                       className="form-control">
                                  {monetizationConst.SCREEN_EVENTS_ID_OPTIONS.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                  ))}
                                </Field>
                                <label>Content Type</label>
                                <Field as="select"
                                       name={`content.screen_events.${index}.one_off_events.${eventIndex}.content_type`}
                                       className="form-control">
                                  {monetizationConst.CONTENT_TYPE_OPTIONS.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                  ))}
                                </Field>
                                <label>AD Type</label>
                                <Field as="select" name={`content.screen_events.${index}.one_off_events.${eventIndex}.ad_type`}
                                       className="form-control">
                                  {monetizationConst.AD_TYPE_OPTIONS.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                  ))}
                                </Field>
                                <label>IAP Type</label>
                                <Field type="text" name={`content.screen_events.${index}.one_off_events.${eventIndex}.iap_type`}
                                       className="form-control"/>
                                <label>AD Unit ID</label>
                                <Field type="text" name={`content.screen_events.${index}.one_off_events.${eventIndex}.ad_unit_id`}
                                       className="form-control"/>
                                <label>IAP Entitlement Id</label>
                                <Field type="text" name={`content.screen_events.${index}.one_off_events.${eventIndex}.iap_entitlement_id`}
                                       className="form-control"/>
                                <button type="button" className="btn btn-danger btn-sm mt-2"
                                        onClick={() => remove(eventIndex)}>Delete
                                </button>
                              </div>
                            ))}
                            <button type="button" className="btn btn-primary btn-sm" onClick={() => push({
                              id: monetizationConst.SCREEN_EVENTS_ID_OPTIONS[0]?.value || "",
                              content_type: monetizationConst.CONTENT_TYPE_OPTIONS[0]?.value || "",
                              ad_type: monetizationConst.AD_TYPE_OPTIONS[0]?.value || "",
                              iap_type: "",
                              ad_unit_id: "",
                              iap_entitlement_id: ""
                            })}
                            >Add One-Off Event
                            </button>
                          </div>
                        )}
                      </FieldArray>

                      {/* Persistent Events */}
                      <h5>Persistent Events</h5>
                      <FieldArray name={`content.screen_events.${index}.persistent_events`}>
                        {({push, remove}) => (
                          <div>
                            {screenEvent?.persistent_events?.map((event, eventIndex) => (
                              <div key={eventIndex} className="mb-2 p-2 border">
                                <label>ID</label>
                                <Field as="select" name={`content.screen_events.${index}.persistent_events.${eventIndex}.id`}
                                       className="form-control">
                                  {monetizationConst.SCREEN_EVENTS_ID_OPTIONS.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                  ))}
                                </Field>
                                <label>Content Type</label>
                                <Field as="select"
                                       name={`content.screen_events.${index}.persistent_events.${eventIndex}.content_type`}
                                       className="form-control">
                                  {monetizationConst.CONTENT_TYPE_OPTIONS.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                  ))}
                                </Field>
                                <label>Ad Type</label>
                                <Field as="select"
                                       name={`content.screen_events.${index}.persistent_events.${eventIndex}.ad_type`}
                                       className="form-control">
                                  {monetizationConst.AD_TYPE_OPTIONS.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                  ))}
                                </Field>
                                <label>IAP Type</label>
                                <Field type="text"
                                       name={`content.screen_events.${index}.persistent_events.${eventIndex}.iap_type`}
                                       className="form-control"/>
                                <label>Ad Unit Id</label>
                                <Field type="text"
                                       name={`content.screen_events.${index}.persistent_events.${eventIndex}.ad_unit_id`}
                                       className="form-control"/>
                                <label>Iap Entitlement Id</label>
                                <Field type="text"
                                       name={`content.screen_events.${index}.persistent_events.${eventIndex}.iap_entitlement_id`}
                                       className="form-control"/>
                                <button type="button" className="btn btn-danger btn-sm mt-2"
                                        onClick={() => remove(eventIndex)}>Delete
                                </button>
                              </div>
                            ))}
                            <button type="button" className="btn btn-primary btn-sm" onClick={() => push({
                              id: monetizationConst.SCREEN_EVENTS_ID_OPTIONS[0]?.value || "",
                              content_type: monetizationConst.CONTENT_TYPE_OPTIONS[0]?.value || "",
                              ad_type: monetizationConst.AD_TYPE_OPTIONS[0]?.value || "",
                              iap_type: "",
                              ad_unit_id: "",
                              iap_entitlement_id: ""
                            })}
                            >Add Persistent Event
                            </button>
                          </div>
                        )}
                      </FieldArray>

                      {/* alternative_events */}
                      <h5>Alternative Events</h5>
                      <FieldArray name={`content.screen_events.${index}.alternative_events`}>
                        {({push, remove}) => (
                          <div>
                            {screenEvent?.alternative_events?.map((event, eventIndex) => (
                              <div key={eventIndex} className="mb-2 p-2 border">
                                <label>ID</label>
                                <Field as="select" name={`content.screen_events.${index}.alternative_events.${eventIndex}.id`}
                                       className="form-control">
                                  {monetizationConst.SCREEN_EVENTS_ID_OPTIONS.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                  ))}
                                </Field>
                                <label>Content Type</label>
                                <Field as="select"
                                       name={`content.screen_events.${index}.alternative_events.${eventIndex}.content_type`}
                                       className="form-control">
                                  {monetizationConst.CONTENT_TYPE_OPTIONS.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                  ))}
                                </Field>
                                <label>Ad Type</label>
                                <Field as="select"
                                       name={`content.screen_events.${index}.alternative_events.${eventIndex}.ad_type`}
                                       className="form-control">
                                  {monetizationConst.AD_TYPE_OPTIONS.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                  ))}
                                </Field>
                                <label>IAP Type</label>
                                <Field type="text"
                                       name={`content.screen_events.${index}.alternative_events.${eventIndex}.iap_type`}
                                       className="form-control"/>
                                <label>Ad Unit Id</label>
                                <Field type="text"
                                       name={`content.screen_events.${index}.alternative_events.${eventIndex}.ad_unit_id`}
                                       className="form-control"/>
                                <label>Iap Entitlement Id</label>
                                <Field type="text"
                                       name={`content.screen_events.${index}.alternative_events.${eventIndex}.iap_entitlement_id`}
                                       className="form-control"/>
                                <button type="button" className="btn btn-danger btn-sm mt-2"
                                        onClick={() => remove(eventIndex)}>Delete
                                </button>
                              </div>
                            ))}
                            <button type="button" className="btn btn-primary btn-sm" onClick={() => push({
                              id: monetizationConst.SCREEN_EVENTS_ID_OPTIONS[0]?.value || "",
                              content_type: monetizationConst.CONTENT_TYPE_OPTIONS[0]?.value || "",
                              ad_type: monetizationConst.AD_TYPE_OPTIONS[0]?.value || "",
                              iap_type: "",
                              ad_unit_id: "",
                              iap_entitlement_id: ""
                            })}
                            >Add Alternative Event
                            </button>
                          </div>
                        )}
                      </FieldArray>

                      <button type="button" className="btn btn-danger btn-sm mt-3" onClick={() => remove(index)}>Delete
                        Screen Event
                      </button>
                    </div>
                  ))}
                  <button type="button" className="btn btn-primary"
                          onClick={() => push({screen: '', one_off_events: [], persistent_events: [], alternative_events: []})}>Add Screen
                    Event
                  </button>
                </div>
              )}
            </FieldArray>
            )}
            <button type="submit" className="btn btn-success mt-3" disabled={isLoading}>
              {isLoading ? "Saving..." : "Save Configuration"}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MonetizationEditForm;