import { faUser } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import MonetizationView from "./View";
import MonetizationImport from "./Import";
import { Toast } from "react-bootstrap";

function MonetizationList() {
  const [monetizationList, setMonetizationList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [selectedMonetization, setSelectedMonetization] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const handleView = (monetization) => {
    setSelectedMonetization(monetization);
    setShowModal(true);
  };

  const handleImport = (monetization) => {
    setSelectedMonetization(monetization);
    setShowImportModal(true);
  };

  const onSaveSuccessfully = () => {
    getMonetizationList().then(() => setShowToast(true));
  };

  useEffect(() => {
    getMonetizationList().then(r => {});
  }, []);

  let getMonetizationList = async () => {
    try {
      const res = await axios.get("https://picfixer.app/api/monetization/get");
      setMonetizationList(res.data?.data?.items ?? []);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  let handleDelete = async (id) => {
    try {
      const confirmDelete = window.confirm("Are you sure do you want to delete the data?");
      if (confirmDelete) {
        await axios.post(`https://picfixer.app/api/monetization/delete`, {
          id: id
        });
        await getMonetizationList();
      }
    } catch (error) {
      console.log(error);
    }
  }

  let handleDuplicate = async (id) => {
    try {
      const confirmDelete = window.confirm("Are you sure do you want to duplicate the data?");
      if (confirmDelete) {
        await axios.post(`https://picfixer.app/api/monetization/duplicate`, {
          id: id
        });
        await getMonetizationList();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const filteredList = monetizationList.filter(monetization =>
    (filterText === "" || monetization?.code.toLowerCase().includes(filterText.toLowerCase()))
  );

// Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredList.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredList.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
      <>
        <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide style={{ position: 'fixed', top: 20, right: 20, backgroundColor: '#28a745', color: 'white' }}>
          <Toast.Body>Import successful!</Toast.Body>
        </Toast>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Monetization List</h1>
          <div className="align-items-end">
            <Link to="/portal/create-monetization" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
              <FontAwesomeIcon icon={faUser} className="creating-monetization" />
              Create Monetization
            </Link>
          </div>
        </div>
        {/* <!-- DataTables --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">DataTables</h6>
          </div>
          <div className="card-body">
            <div className="row pl-3">
              <div className="mb-3 col-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Filter by Name"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                />
              </div>
            </div>

            {
              isLoading ? <img src='https://media.giphy.com/media/ZO9b1ntYVJmjZlsWlm/giphy.gif' alt="Loading" />
                  : <div className="table-responsive">
                    <nav>
                      <ul className="pagination">
                        {[...Array(totalPages).keys()].map(number => (
                            <li key={number + 1} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
                              <a onClick={() => paginate(number + 1)} className="page-link">
                                {number + 1}
                              </a>
                            </li>
                        ))}
                      </ul>
                    </nav>
                    <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                      <thead>
                      <tr>
                        <th>Id</th>
                        <th>Version</th>
                        <th>Platform</th>
                        <th>Environment</th>
                        <th>Threshold milliseconds</th>
                        <th>Number Of App Events</th>
                        <th>Number Of Screen Events</th>
                        <th>Action</th>
                      </tr>
                      </thead>
                      <tfoot>
                      <tr>
                        <th>Id</th>
                        <th>Version</th>
                        <th>Platform</th>
                        <th>Environment</th>
                        <th>Threshold milliseconds</th>
                        <th>Number Of App Events</th>
                        <th>Number Of Screen Events</th>
                        <th>Action</th>
                      </tr>
                      </tfoot>
                      <tbody>
                      {currentItems.map((monetization) => {
                        return (
                            <tr key={monetization.id}>
                              <td>{monetization.id}</td>
                              <td>{monetization?.version}</td>
                              <td>{monetization?.platform}</td>
                              <td>{monetization?.environment}</td>
                              <td>{monetization?.content?.event_threshold_in_milliseconds}</td>
                              <td>{monetization?.content?.app_events?.length ?? 0}</td>
                              <td>{monetization?.content?.screen_events?.length ?? 0}</td>
                              <th>
                                <button onClick={() => handleView(monetization)} className="btn btn-success btn-sm mr-1" >View</button>
                                <Link to={`/portal/monetization-edit/${monetization.id}`} className='btn btn-info btn-sm mr-1'>Edit</Link>
                                <button onClick={() => handleDuplicate(monetization.id)} className='btn btn-warning btn-sm mr-1'>Duplicate</button>
                                <button onClick={() => handleImport(monetization)} className="btn btn-success btn-sm mr-1" >Import</button>
                                <button onClick={() => handleDelete(monetization.id)} className='btn btn-danger btn-sm mr-1'>Delete</button>
                              </th>
                            </tr>
                        )
                      })}
                      </tbody>
                    </table>
                    <nav>
                      <ul className="pagination">
                        {[...Array(totalPages).keys()].map(number => (
                            <li key={number + 1} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
                              <a onClick={() => paginate(number + 1)} className="page-link">
                                {number + 1}
                              </a>
                            </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
            }
          </div>
          {showModal && (
            <MonetizationView show={showModal} onClose={() => setShowModal(false)} monetization={selectedMonetization} />
          )}

          {showImportModal && (
            <MonetizationImport show={showImportModal} onClose={() => setShowImportModal(false)} monetization={selectedMonetization}
              onSaveSuccessfully={onSaveSuccessfully}
            />
          )}
        </div>
      </>
  )
}

export default MonetizationList
